import TwCitySelector from 'tw-city-selector'

$(document).ready(() => {
  // 會員
  var userArea = $('#user-area');
  if (userArea.length) {
    new TwCitySelector({
                     el: '#user-area',
               elCounty: '#user_city',
             elDistrict: '#user_dist',
              elZipcode: '#user_zip_code',
        countyFieldName: $('#user_city').attr('name'),
      districtFieldName: $('#user_dist').attr('name'),
       zipcodeFieldName: $('#user_zip_code').attr('name'),
    });
  }
  // 訂單
  var recipientArea = $('#recipient-area');
  if (recipientArea.length) {
    new TwCitySelector({
                     el: '#recipient-area',
               elCounty: '#order_receiver_city',
             elDistrict: '#order_receiver_dist',
        countyFieldName: $('#order_receiver_city').attr('name'),
      districtFieldName: $('#order_receiver_dist').attr('name'),
    });
  }
  // 套量
  var fittingArea = $('#fitting-area');
  let $city_el = $('#fitting_city');
  let $dist_el = $('#fitting_dist');
  if (fittingArea.length) {
    new TwCitySelector({
                     el: '#fitting-area',
               elCounty: '#fitting_city',
             elDistrict: '#fitting_dist',
        countyFieldName: $city_el.attr('name'),
      districtFieldName: $dist_el.attr('name'),
      countyValue: $city_el.attr('defult-value'),
      districtValue: $dist_el.attr('defult-value'),
    });
  }
});
